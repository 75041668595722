"use client"

import { Button } from "@nextui-org/button"
import { Input } from "@nextui-org/input"
import clsx from "clsx"
import { redirect } from "next/navigation"
import React from "react"

import { fontMontserrat } from "@/config/fonts"

export const MailMagazine = () => {
  const [value, setValue] = React.useState("")

  const validateEmail = (v: string) =>
    v.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i)

  const isInvalid = React.useMemo(() => {
    if (value === "") return false

    return !validateEmail(value)
  }, [value])

  const [isSubmitted, setIsSubmitted] = React.useState(false)

  const handleSubmit = () => {
    if (isInvalid) return

    setIsSubmitted(true)
    if (isSubmitted) {
      redirect("/")
    }
  }

  return (
    <div className="flex flex-col gap-6 py-20">
      <div className="flex items-center gap-2">
        <p
          className={clsx(
            "text-md text-center font-mono font-bold sm:text-lg",
            fontMontserrat.variable,
          )}
        >
          最新のレイド情報を受け取ろう
        </p>
      </div>
      <form
        className="flex flex-col gap-4"
        action="https://docs.google.com/forms/d/e/1FAIpQLSdye45VeqNisA70I3sX1Rtcki6IPziloiT-Jg1K67kOCm2dGg/formResponse"
        method="POST"
        onSubmit={handleSubmit}
        target="hidden_iframe"
      >
        <Input
          name="entry.624027469"
          type="email"
          label="メールアドレス"
          className="w-full focus:border-primary focus:outline-none"
          errorMessage={isInvalid && "メールアドレスが正しくありません"}
          onValueChange={setValue}
          color={isInvalid ? "danger" : "secondary"}
          variant="bordered"
          size="lg"
          radius="sm"
          value={isSubmitted ? "" : value}
        />
        <Button color="primary" radius="sm" size="md" type="submit">
          フォロー
        </Button>
        {isSubmitted && (
          <p className="text-center text-foreground">送信されました！</p>
        )}
      </form>
    </div>
  )
}
